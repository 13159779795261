import axios, { AxiosResponse } from "axios";
import { Common } from "./Common";
import { Page, Pageable } from "./Page";
import { Word } from "./Word";

export class ApiRequest {

  static checkMe(): Promise<AxiosResponse<string>> {
    return axios.get<string>(Common.apiUri.checkMe, Common.restConfig);
  }

  static logIn(loginData?: { username: string; password: string }): Promise<AxiosResponse<void>> {
    return axios.post<void>(Common.apiUri.login, loginData, Common.restConfig);
  }

  static logOut(): Promise<AxiosResponse<void>> {
    return axios.post<void>(Common.apiUri.logout, null, Common.restConfig)
  }

  static getWords(params?: { search?: string } & Pageable): Promise<AxiosResponse<Page<Word>>> {
    const urlSearchParams = new URLSearchParams();
    params?.search && urlSearchParams.append('q', params.search);
    params?.page && urlSearchParams.append('page', `${params.page}`);
    params?.size && urlSearchParams.append('size', `${params.size}`);
    return axios.get<Page<Word>>(Common.apiUri.words, {
      ...Common.restConfig, params: urlSearchParams
    });
  }

  static saveWord(word: Word): Promise<AxiosResponse<Word>> {
    if (word.id) {
      return axios.put<Word>(`${Common.apiUri.words}/${word.id}`, word, {
        ...Common.restConfig
      });
    }
    return axios.post<Word>(Common.apiUri.words, word, {
      ...Common.restConfig
    });
  }

  static delWord(word: Word): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`${Common.apiUri.words}/${word.id}`, {
      ...Common.restConfig
    })
  }
}
