import React, { useState } from "react";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import { Word } from "../../common/Word";
import { FiSave } from 'react-icons/fi'
import { BsInfoCircle } from 'react-icons/bs'
import { Common } from "../../common/Common";
import { ApiRequest } from "../../common/ApiRequest";
import ErrorMessages from "../../common/ErrorMessages";
import { useAppDispatch } from "../../store/Hooks";
import { setChange } from "../../store/WordSlice";

const FcWordForm = ({ word }: { word?: Word }) => {
  const dispatch = useAppDispatch()
  const [saveError, setSaveError] = useState<string | boolean>('');
  const [isSaved, setSaved] = useState<boolean>(false);
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const contextForm = e.currentTarget;
    const formData = new FormData(contextForm);
    const word2Save = {
      ...word,
      pl: formData.get('pl') as string,
      en: formData.get('en') as string,
      commentEn: formData.get('enc') as string,
      commentPl: formData.get('plc') as string,
    }
    ApiRequest.saveWord(word2Save)
      .then(() => {
        Common.timeout(setSaved, true);
        dispatch(setChange());
        contextForm.reset();
      })
      .catch((reason: ErrorMessages) => {
        const error = reason.response?.data?.message?.join(', ');
        error && Common.timeout(setSaveError, error, 5);
      })
  }
  return (
    <Form onSubmit={submitHandler}>
      <FloatingLabel controlId="en" label="EN word" className="mb-3">
        <Form.Control placeholder="Write EN" name="en" defaultValue={word?.en} required minLength={1} maxLength={255}/>
      </FloatingLabel>
      <FloatingLabel controlId="pl" label="PL word" className="mb-3">
        <Form.Control placeholder="Write PL" name="pl" defaultValue={word?.pl} required minLength={1} maxLength={255}/>
      </FloatingLabel>
      <FloatingLabel controlId="commentEn" label="EN comment" className="mb-3">
        <Form.Control as="textarea" placeholder="EN comment" name="enc" required minLength={1} maxLength={255}
                      defaultValue={word?.commentEn} style={{ height: '80px' }}/>
      </FloatingLabel>
      <FloatingLabel controlId="commentPl" label="PL comment" className="mb-3">
        <Form.Control as="textarea" placeholder="PL comment" name="plc" required minLength={1} maxLength={255}
                      defaultValue={word?.commentPl} style={{ height: '80px' }}/>
      </FloatingLabel>
      {isSaved &&
        <Alert variant={'success'}>
          <BsInfoCircle/> Word has been saved!
        </Alert>
      }
      {saveError &&
        <Alert variant={'danger'}>
          <BsInfoCircle/> {saveError}
        </Alert>
      }
      <div className="modal-footer px-0">
        <Button variant="primary" type="submit">
          <FiSave/> Save word
        </Button>
      </div>
    </Form>
  )
}

export default FcWordForm;
