import React from "react";
import { Button } from "react-bootstrap";
import { FiLogOut } from "react-icons/fi";
import { ApiRequest } from "../../common/ApiRequest";
import { useAppDispatch } from "../../store/Hooks";
import { resetPrincipal } from "../../store/PrincipalSlice";

const FcLogoutBtn = () => {
  const dispatch = useAppDispatch();
  const logoutHandler = () => {
    ApiRequest.logOut().then(() => dispatch(resetPrincipal()))
  }
  return (
    <Button variant="warning" onClick={logoutHandler}>
      <FiLogOut/> Logout
    </Button>
  )
}

export default FcLogoutBtn;
