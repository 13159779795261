import React from "react";
import { Pagination } from "react-bootstrap";
import { Page } from "../../common/Page";

interface PropsType {
  items: Page<unknown>;
  changePage: (page: number) => void;
}

const FcPagination = ({ items, changePage }: PropsType) => {
  const changePageHandler = (page: number) => {
    changePage(page);
  }
  return (
    <Pagination>
      <Pagination.First onClick={() => changePageHandler(0)}/>
      <Pagination.Prev disabled={items.number - 1 < 0}
                       onClick={() => changePageHandler(items.number - 1)}/>
      <Pagination.Item active>
        {items.number + 1} / {items.totalPages}
      </Pagination.Item>
      <Pagination.Next disabled={items.number + 1 >= items.totalPages}
                       onClick={() => changePageHandler(items.number + 1)}/>
      <Pagination.Last onClick={() => changePageHandler(items.totalPages - 1)}/>
    </Pagination>
  );
}

export default FcPagination;
