import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store'

interface SearchState {
  search?: string
}

const initialState: SearchState = {
  search: undefined,
}

const SearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
  },
})

export const { setSearch } = SearchSlice.actions;
export const getSearch = (state: RootState) => state.search.search;
export default SearchSlice.reducer;
