export const Common = {
  apiUri: {
    checkMe: '/rest/me',
    logout: '/rest/logout',
    login: '/rest/login',
    words: '/rest/word',
  },
  restConfig: {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true
  },
  timeout: (fn: (state: any) => void, state: any, seconds: number = 3) => {
    fn(state);
    setTimeout(() => fn(undefined), seconds * 1000);
  }
}
