import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { getPrincipal } from "../../store/PrincipalSlice";
import { useAppSelector } from "../../store/Hooks";

const FcLoggedGuard = ({ children }: { children: ReactNode }) => {
  const principal = useAppSelector(getPrincipal)
  return (
    <>
      {principal ? children : <Navigate to="/login"/>}
    </>
  );
}

export default FcLoggedGuard;
