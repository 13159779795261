import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import Words from "../Words/Words";
import FcNavbar from "../../components/FcNavbar/FcNavbar";
import Reviews from "../Reviews/Reviews";
import Login from "../Login/Login";
import FcLoggedGuard from "../../components/FcLoggedGuard/FcLoggedGuard";
import { Provider } from "react-redux";
import { store } from '../../store/Store';
import FcCheckMe from "../../components/FcCheckMe/FcCheckMe";

const router = createBrowserRouter([
  {
    path: "/words",
    element: (
      <FcLoggedGuard>
        <FcNavbar/>
        <Words/>
      </FcLoggedGuard>
    ),
  },
  {
    path: "/reviews",
    element: (
      <FcLoggedGuard>
        <FcNavbar/>
        <Reviews/>
      </FcLoggedGuard>
    ),
  },
  {
    path: "/login",
    element: (
      <>
        <Login/>
      </>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/words" replace/>
  }
]);

function App() {
  return (
    <>
      <Provider store={store}>
        <FcCheckMe/>
        <RouterProvider router={router}/>
      </Provider>
    </>
  );
}

export default App;
