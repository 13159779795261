import React from "react";
import { Card } from "react-bootstrap";
import { FiLogIn } from 'react-icons/fi'
import { Navigate } from "react-router-dom";
import FcLoginForm from "../../components/FcLoginForm/FcLoginForm";
import { getPrincipal } from "../../store/PrincipalSlice";
import { useAppSelector } from "../../store/Hooks";

const Login = () => {
  const principal = useAppSelector(getPrincipal);
  return (
    <>
      {principal ? <Navigate to="/words"/> :
        <div className="d-flex justify-content-center mt-5">
          <Card style={{ width: 340 }}>
            <Card.Header as="h5">
              <FiLogIn/> Please fill form to login
            </Card.Header>
            <Card.Body>
              <FcLoginForm/>
            </Card.Body>
          </Card>
        </div>
      }
    </>
  )
}

export default Login;
