import React, { useEffect } from "react";
import { useAppDispatch } from "../../store/Hooks";
import { ApiRequest } from "../../common/ApiRequest";
import { resetPrincipal, setPrincipal } from "../../store/PrincipalSlice";

const FcCheckMe = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    ApiRequest.checkMe()
      .then(value => dispatch(setPrincipal(value.data)))
      .catch(() => dispatch(resetPrincipal()));
  }, [])
  return null;
}

export default FcCheckMe;
