import React from 'react';

const FcDate = ({ children }: { children?: string }) => {
  return (
    !children ? null :
      <small className="text-muted">
        {new Date(children).toLocaleDateString()}
      </small>
  );
}

export default FcDate;
