import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from './Store'

interface WordState {
  change?: Date
}

const initialState: WordState = {
  change: undefined,
}

const WordSlice = createSlice({
  name: 'word',
  initialState,
  reducers: {
    setChange: (state) => {
      state.change = new Date();
    },
  },
})

export const { setChange } = WordSlice.actions;
export const getChange = (state: RootState) => state.word.change;
export default WordSlice.reducer;
