import React from 'react';
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import FcSearchEngine from "../FcSearchEngine/FcSearchEngine";
import FcButtonModal from "../FcButtonModal/FcButtonModal";
import FcWordForm from "../FcWordForm/FcWordForm";
import FcLogoutBtn from "../FcLogoutBtn/FcLogoutBtn";
import { BiAddToQueue } from 'react-icons/bi'

const FcNavbar = () => {
  return (
    <Navbar bg="light" expand="md">
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">FlashCards</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll"/>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto" navbarScroll>
            <Nav.Link as={NavLink} to="/words">Words</Nav.Link>
            <Nav.Link as={NavLink} to="/reviews">Review</Nav.Link>
          </Nav>
          <div className="d-flex gap-2 flex-wrap justify-content-end">
            <FcSearchEngine/>
            <div className="d-flex gap-2 flex-wrap">
              <FcButtonModal
                heading="Add new word"
                ModalButton={
                  <Button variant="primary"><BiAddToQueue/> Add new</Button>
                }>
                <FcWordForm/>
              </FcButtonModal>
              <FcLogoutBtn/>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default FcNavbar;
