import React, { useState } from "react";
import { ApiRequest } from "../../common/ApiRequest";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import { FiLogIn } from "react-icons/fi";
import ErrorMessages from "../../common/ErrorMessages";
import { Common } from "../../common/Common";
import { BsInfoCircle } from "react-icons/bs";
import { useAppDispatch } from "../../store/Hooks";
import { resetPrincipal, setPrincipal } from "../../store/PrincipalSlice";

interface LoginData {
  username: string;
  password: string;
}

const FcLoginForm = () => {
  const dispatch = useAppDispatch();
  const [loginError, setLoginError] = useState<string | boolean | undefined>('');
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const contextForm = e.currentTarget;
    const formData = new FormData(contextForm);
    const loginData = {
      username: formData.get('username') as string,
      password: formData.get('password') as string,
    } as LoginData;
    ApiRequest.logIn(loginData)
      .then(() => dispatch(setPrincipal(loginData.username)))
      .catch((reason: ErrorMessages) => {
        const error = reason.response?.data?.message?.join(', ');
        error && Common.timeout(setLoginError, error, 5);
        dispatch(resetPrincipal());
      })
  }
  return (
    <Form onSubmit={submitHandler}>
      <FloatingLabel controlId="en" label="Username" className="mb-3">
        <Form.Control placeholder="Write username" name="username" minLength={8} maxLength={50}/>
      </FloatingLabel>
      <FloatingLabel controlId="pl" label="Password" className="mb-3">
        <Form.Control placeholder="Write password" name="password" type="password" minLength={25} maxLength={50}/>
      </FloatingLabel>
      {loginError &&
        <Alert variant={'danger'}>
          <BsInfoCircle/> {loginError}
        </Alert>
      }
      <div className="d-flex justify-content-end">
        <Button type="submit" variant="primary">
          <FiLogIn/> Login
        </Button>
      </div>
    </Form>
  )
}

export default FcLoginForm;
