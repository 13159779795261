import React from "react";
import { Button, Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useAppDispatch } from "../../store/Hooks";
import { setSearch } from "../../store/SearchSlice";

const FcSearchEngine = () => {
  const dispatch = useAppDispatch()
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(setSearch(data.get('search') as string));
  }
  return (
    <Form onSubmit={submitHandler} className="d-flex">
      <div className="me-2">
        <Form.Control name="search" type="search" placeholder="Search" maxLength={50}/>
      </div>
      <Button type="submit" variant="success">
        <BsSearch/> Search
      </Button>
    </Form>
  )
}
export default FcSearchEngine;
