import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store'

interface PrincipalState {
  principal?: string
}

const initialState: PrincipalState = {
  principal: undefined,
}

const PrincipalSlice = createSlice({
  name: 'principal',
  initialState,
  reducers: {
    resetPrincipal: (state) => {
      state.principal = undefined;
    },
    setPrincipal: (state, action: PayloadAction<string>) => {
      state.principal = action.payload
    },
  },
})

export const { resetPrincipal, setPrincipal } = PrincipalSlice.actions;
export const getPrincipal = (state: RootState) => state.principal.principal;
export default PrincipalSlice.reducer;
