import { configureStore } from '@reduxjs/toolkit'
import PrincipalSlice from "./PrincipalSlice";
import SearchSlice from "./SearchSlice";
import WordSlice from "./WordSlice";

export const store = configureStore({
  reducer: {
    principal: PrincipalSlice,
    search: SearchSlice,
    word: WordSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
