import React, { useEffect, useState } from 'react';
import { Word } from "../../common/Word";
import { ApiRequest } from "../../common/ApiRequest";
import { Container, Row } from "react-bootstrap";
import FcWord from "../../components/FcWord/FcWord";
import FcPagination from "../../components/FcPagination/FcPagination";
import { Page } from "../../common/Page";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { getSearch } from "../../store/SearchSlice";
import { getChange, setChange } from "../../store/WordSlice";

const Words = () => {
  const search = useAppSelector(getSearch)
  const lastChange = useAppSelector(getChange)
  const dispatch = useAppDispatch();
  const [words, setWords] = useState<Page<Word> | undefined>(undefined);
  const [page, setPage] = useState<number>(0);
  useEffect(() => {
    ApiRequest.getWords({ search, size: 40, page })
      .then(value => {
        setWords(value.data);
        setPage(value.data.number > value.data.totalPages ? 0 : value.data.number);
      });
  }, [search, lastChange, page]);
  const removeHandler = (word: Word): void => {
    window.confirm("Are you sure?") && ApiRequest.delWord(word).then(() => dispatch(setChange()));
  }
  return (
    <>
      <Container fluid={true} className="my-4">
        <Row xs={1} md={2} xl={4} className="g-4">
          {words?.content?.map(word => <FcWord key={word.id} removeWord={() => removeHandler(word)} word={word}/>)}
        </Row>
      </Container>
      {words?.content && words?.totalPages > 1 &&
        <div className="d-flex justify-content-center my-4">
          <FcPagination items={words} changePage={setPage}/>
        </div>
      }
    </>
  )
}

export default Words;
