import React, { ReactElement, ReactNode, useState } from 'react';
import { Modal } from "react-bootstrap";

interface PropsType {
  children: ReactNode,
  heading: ReactNode
  ModalButton: ReactElement,
}

const FcButtonModal = ({ children, heading, ModalButton }: PropsType) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const EnhancedModalButton = () => {
    // eslint-disable-next-line
    return <ModalButton.type {...ModalButton.props} onClick={handleShow}/>;
  }
  return (
    <>
      <EnhancedModalButton/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}

export default FcButtonModal;
