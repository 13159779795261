import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Word } from "../../common/Word";
import FcDate from "../FcDate/FcDate";
import { Button } from "react-bootstrap";
import styles from './FcWord.module.scss'
import { FaPencilAlt, FaStarOfLife, FaTimes } from 'react-icons/fa';
import FcButtonModal from "../FcButtonModal/FcButtonModal";
import FcWordForm from "../FcWordForm/FcWordForm";
import React, { useState } from "react";
import { HiEye, HiEyeOff } from 'react-icons/hi';

interface PropsType {
  review?: boolean;
  word: Word;
  removeWord: () => void;
}

const FcWord = ({ word, removeWord, review }: PropsType) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const togglePreview = () => {
    setShowPreview(prevState => !prevState);
  }
  const hiddenElement = [...Array(5).keys()]
    .map((idx) => <FaStarOfLife key={idx} size={'0.8rem'}/>);
  return (
    <Col>
      <Card>
        <Card.Body>
          {review &&
            <Button onClick={togglePreview} className={styles.eyeBtn} size="sm" variant="primary">
              {showPreview ? <HiEyeOff/> : <HiEye/>}
            </Button>
          }
          <Button onClick={removeWord} className={styles.removeBtn} size="sm" variant="danger">
            <FaTimes/>
          </Button>
          <FcButtonModal
            heading="Edit word"
            ModalButton={
              <Button className={styles.editBtn} size="sm" variant="warning"><FaPencilAlt/></Button>
            }>
            <FcWordForm word={word}/>
          </FcButtonModal>
          <Card.Title>{!review || showPreview ? word.en : hiddenElement}</Card.Title>
          <Card.Subtitle>{word.pl}</Card.Subtitle>
          <Card.Text as={'div'} className="d-flex justify-content-between">
            <div>
              <div>{!review || showPreview ? word.commentEn : hiddenElement}</div>
              <div>{word.commentPl}</div>
            </div>
            <FcDate>{word.createdAt}</FcDate>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default FcWord;
